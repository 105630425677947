import * as React from 'react';
import { graphql } from 'gatsby';
import Layout from '../../components/layout/layout.jsx';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import { HCELogo, backgroundColorHeader } from './hce.module.scss'
import { motion } from 'framer-motion';
import Button from "@mui/material/Button";
import SEO from "../../components/seo"

const hceLink = "https://hce-med.com"
export const query = graphql`
  query Localess($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
export default function HCE() {
    const translate = useTranslation().t;
    return (
        <Layout>
          <SEO title="HCE" />
           <div className={backgroundColorHeader}>
            <svg className={HCELogo} align="center" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="450" height="315.661" viewBox="0 0 680 335.661">
                    <image id="Image_1" data-name="Image 1" width="161" height="55" transform="translate(448 253.661)"/>
                    <g id="Group_1" data-name="Group 1" transform="translate(-405 -259.339)">
                      <rect id="Rectangle_1" data-name="Rectangle 1" width="204" height="62" transform="translate(405 290)" fill="#0159a1"/>
                      <path id="Path_1" data-name="Path 1" d="M451.077,255.651v25.811H406.83Z" transform="translate(0 3.687)" fill="#0159a1"/>
                    </g>
                    <g id="Group_2" data-name="Group 2" transform="translate(-405 -145.339)">
                      <rect id="Rectangle_1-2" data-name="Rectangle 1" width="204" height="62" transform="translate(405 290)" fill="#0159a1"/>
                      <path id="Path_1-2" data-name="Path 1" d="M451.077,255.651v25.811H406.83Z" transform="translate(0 3.687)" fill="#0159a1"/>
                    </g>
                    <g id="Group_3" data-name="Group 3" transform="translate(-405 -31.339)">
                      <rect id="Rectangle_1-3" data-name="Rectangle 1" width="204" height="62" transform="translate(405 290)" fill="#0159a1"/>
                      <path id="Path_1-3" data-name="Path 1" d="M451.077,255.651v25.811H406.83Z" transform="translate(0 3.687)" fill="#0159a1"/>
                    </g>
                    <text id="HEALTH_CENTER_OF_EXCELLENCE" data-name="HEALTH CENTER OF EXCELLENCE" transform="translate(228 80.661)" fill="#0159a1" font-size="70" font-family="TimesNewRomanPSMT, Times New Roman"><tspan x="0" y="0">HEALTH</tspan><tspan x="0" y="80">CENTER</tspan><tspan x="0" y="160">OF</tspan><tspan x="0" y="240">EXCELLENCE</tspan></text>
            </svg>
           </div>
            <div class="inner" style={{ width: "90%", marginTop: "3rem", paddingBottom: "1rem"}}>
                <Typography sx={{ marginTop: "1rem", marginBottom: "3rem", marginLeft: "auto", marginRight: "auto", whiteSpace: "pre-line" }} >
                    {translate("hce-description")}
                </Typography>
                <motion.button
                            style={{ border: "none", margin: "auto", display: "flex" }}
                            whileHover={{
                                scale: 1.1,
                            }}
                            whileTap={{ scale: 0.9 }}
                        >
                            {
                                    <Button href={hceLink} target="_blank" size="large" variant="contained" color="secondary" >
                                        <Typography align="center" color="primary" variant="button">
                                          {translate("visit-website")}
                                        </Typography>
                                    </Button>
                            }
                        </motion.button>
            </div>
        </Layout>
  )
}
